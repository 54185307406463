// Here you can add other styles
.sign-up {
  background-color: #2eb85c;
  border: none;
  border-radius: 5px;
  padding: 7px;
}

.pointer {
  cursor: pointer;
}

.blue {
  color: #25aacc;
}

.text-right {
  text-align: right;
}

.center {
  text-align: center;
}

.text-white {
  color: #ffffff !important;
}

 /* custom scroll bar */
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }